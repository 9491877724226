import { notification } from 'antd';

const validMimeTypes = [
    'application/pdf',
    'text/plain',
    'text/csv',
    'text/x-csv',
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/webp'
];

const imageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];

export const beforeUpload = (file) => {
    return new Promise((resolve, reject) => {
        // Check if it's a pasted image (blob)
        const isPastedImage = file instanceof Blob && !file.name;

        // For pasted images, we'll assume it's a valid image type
        const fileType = isPastedImage ? 'image/png' : file.type;

        if (!validMimeTypes.includes(fileType)) {
            notification.error({
                placement: 'top',
                message: 'Currently only PDF, CSV, TXT, PNG, JPEG, GIF, WEBP is available to upload'
            });
            reject(new Error('Invalid file type'));
            return;
        }

        // For pasted images, we can't reliably check the file size
        // So we'll skip this check for pasted images
        if (!isPastedImage) {
            const isLt5M = file.size / 1024 / 1024 <= 5;
            if (!isLt5M) {
                notification.error({
                    placement: 'top',
                    message: 'Max file size 5MB'
                });
                reject(new Error('File too large'));
                return;
            }
        }

        if (imageTypes.includes(fileType)) {
            const checkImageDimensions = (imgSrc) => {
                return new Promise((resolve) => {
                    const image = new Image();
                    image.onload = function () {
                        if (this.width > 200 && this.height > 200) {
                            resolve(true);
                        } else {
                            notification.error({
                                placement: 'top',
                                message: 'Min file dimensions 200x200'
                            });
                            resolve(false);
                        }
                    };
                    image.src = imgSrc;
                });
            };

            if (isPastedImage) {
                // For pasted images, we already have the blob
                const imgSrc = URL.createObjectURL(file);
                checkImageDimensions(imgSrc).then((result) => {
                    URL.revokeObjectURL(imgSrc);
                    resolve(result);
                });
            } else {
                // For file uploads, use FileReader as before
                const reader = new FileReader();
                reader.onload = (e) => {
                    checkImageDimensions(e.target.result).then(resolve);
                };
                reader.onerror = () => {
                    notification.error({
                        placement: 'top',
                        message: 'Error reading file'
                    });
                    reject(new Error('Error reading file'));
                };
                reader.readAsDataURL(file);
            }
        } else {
            resolve(true);
        }
    });
};