import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

export default function FullScreenSpin() {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    return (
        <Spin
            style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
            }}
            indicator={antIcon}
            size='large'
        />
    );
}