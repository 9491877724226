import React from 'react';
import Icon from '@ant-design/icons';
import { Image } from 'antd';
import CloseIcon from '../../../components/Icons/CloseIcon';
import { getFileExtension } from '../../../utils/getFileExtension';
import styles from './index.module.scss';

const imageTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/webp'];

export const AttachedFile = ({ file, actions = null }) => {
    if (imageTypes.includes(file.type)) {
        return (
            <div className={styles.attached__image}>
                <div className={styles.attached__image__wrapper}>
                    <Image
                        preview
                        alt='preview'
                        src={file.path ? file.path : URL.createObjectURL(file.originFileObj)}
                    />
                </div>
                {actions ? (
                    <div onClick={() => actions.remove()} className={styles.attached__image__close}>
                        <Icon component={CloseIcon} />
                    </div>
                ) : null}
            </div>
        );
    }

    return (
        <div className={styles.attached}>
            <div className={styles.attached__wrapper}>
                <div className={styles.attached__text}>
                    <p>{file.name}</p>
                    {file.type ? <span>{getFileExtension(file.type)}</span> : null}
                </div>
            </div>
            {actions ? (
                <div onClick={() => actions.remove()} className={styles.attached__close}>
                    <Icon component={CloseIcon} />
                </div>
            ) : null}
        </div>
    );
};
