import React, { useMemo, useState } from 'react';
import Markdown from 'react-markdown';
import { useHistory } from 'react-router-dom';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import atomDark from 'react-syntax-highlighter/src/styles/prism/atom-dark';
import {
    CheckOutlined,
    CopyOutlined,
    LoadingOutlined,
    PauseCircleFilled,
    RedoOutlined,
    SoundOutlined
} from '@ant-design/icons';
import { Button, List, Spin, Tooltip } from 'antd';
import cn from 'classnames';
import remarkGfm from 'remark-gfm';
import thinkLogo from '../../../../../../../../assets/images/just-think-ai-icon.jpg';
import CopyIcon from '../../../../../../../../components/Icons/CopyIcon';
import { AttachedFile } from '../../../../../../../../shared/components/AttachedFile';
import { useCopyToClipboard } from '../../hooks';
import { messageService } from '../../service';
import { NewDocModal } from '../../utils';
import { ChatImages } from './components/Images';

const imageTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/webp'];
const antIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;


export const RenderItem = ({
    modal,
    index,
    player,
    onNewMessage,
    item,
    messages,
    auth,
    message,
    conversation,
    isReadLoud,
    setDocToken,
    setIsReadLoud,
    setUrl
}) => {
    const [isReadLoading, setIsReadLoading] = useState(false);

    const [readMessageId, setReadMessageId] = useState(null);

    const history = useHistory();

    const { images, files } = useMemo(() => {
        const images = item?.files?.filter((file) => imageTypes.includes(file.type)) || [];
        const files = item?.files?.filter((file) => !imageTypes.includes(file.type)) || [];
        return { images, files };
    }, [item.files]);

    const onCopyToDoc = async (e) => {
        await message.actions.newDocModal(true);

        const respondHtml = e.target.closest('.chat-message-wrapper')?.querySelector('.chat-message-response');

        modal.confirm(NewDocModal(message, respondHtml, setDocToken, history));
    };

    const onReadAloud = async (messageId) => {
        await player.current.audio.current.pause();
        setReadMessageId(messageId);
        setIsReadLoading(true);
        const { buffer } = await messageService.readAloud(
            conversation.state.current._id,
            messageId,
            auth.profile.systemVoice
        );

        const byteCharacters = atob(buffer);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'audio/mp3' });

        setUrl(() => URL.createObjectURL(blob));
        setIsReadLoud(true);
        setIsReadLoading(false);
    };

    const onStoploud = async () => {
        setIsReadLoud(false);
        await player.current.audio.current.pause();
    };

    const onRegenerate = () => {
        const formData = new FormData();

        for (let i = 0; i < message.state.uploadFileList?.length; i++) {
            formData.append('file[]', message.state.uploadFileList[i].originFileObj);
        }

        formData.append('regenerate', true);

        onNewMessage(formData);
    };

    const [isCopied, handleCopy] = useCopyToClipboard(1000);

    return (
        <div key={item._id} className='chat-items-wrapper'>
            <List.Item className={cn('chat-message-item', {
                'user-message': !item.respond,
                'files-attached': item?.files?.length
            })}>
                <div className='chat-item-wrapper'>
                    <div className={cn('chat-message', { 'user-message': !item.respond })}>
                        <div className='chat-message-content'>
                            <div className='chat-message-wrapper'>
                                <div className='chat-message-response question'>{item.content}</div>
                                {images.length ? <ChatImages images={images} /> : null}
                                <div className='chat-files'>
                                    {files?.map((file, key) => (
                                        <AttachedFile key={key} file={file} />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </List.Item>
            {!message.state.loader.streaming && !item.respond ? (
                <Spin className='chat-item-spinning' spinning indicator={antIcon}>
                    <List.Item>
                        <div className='chat-item-wrapper'>
                            <div className='chat-message'>
                                <div className='chat-message-logo'>
                                    <img alt='just-think-logo' src={thinkLogo} />
                                </div>
                                <div className='chat-message-content' />
                            </div>
                        </div>
                    </List.Item>
                </Spin>
            ) : null}
            {item.respond ? (
                <List.Item className='chat-message-item'>
                    <div className='chat-item-wrapper'>
                        <div className='chat-message'>
                            <div className='chat-message-logo'>
                                <img alt='just-think-logo' src={thinkLogo} />
                            </div>
                            <div className='chat-message-wrapper'>
                                <div className='chat-message-response'>
                                    <Markdown
                                        remarkPlugins={[remarkGfm]}
                                        components={{
                                            code(props) {
                                                const { children, className, node, ...rest } = props;
                                                const match = /language-(\w+)/.exec(className || '');
                                                return match ? (
                                                    <SyntaxHighlighter
                                                        showLineNumbers
                                                        {...rest}
                                                        PreTag='div'
                                                        language={match[1]}
                                                        style={atomDark}
                                                    >
                                                        {String(children).replace(/\n$/, '')}
                                                    </SyntaxHighlighter>
                                                ) : (
                                                    <code {...rest} className={className}>
                                                        {children}
                                                    </code>
                                                );
                                            }
                                        }}
                                    >
                                        {item.respond}
                                    </Markdown>
                                </div>
                                <div className='chat-btns-wrapper'>
                                    <div className='chat-btn-read'>
                                        {isReadLoading && item._id === readMessageId ? (
                                            <Spin
                                                indicator={
                                                    <LoadingOutlined style={{ fontSize: 18, color: '#1F1F1F' }} spin />
                                                }
                                            />
                                        ) : isReadLoud && item._id === readMessageId ? (
                                            <Tooltip title='Stop'>
                                                <PauseCircleFilled onClick={() => onStoploud()} />
                                            </Tooltip>
                                        ) : (
                                            <Tooltip title='Read Aloud'>
                                                <SoundOutlined onClick={() => onReadAloud(item._id)} />
                                            </Tooltip>
                                        )}
                                    </div>
                                    {isCopied === item._id ? (
                                        <Button type='text' className='chat-btn-copy' icon={<CheckOutlined />} />
                                    ) : (
                                        <Tooltip title='Copy'>
                                            <Button
                                                type='text'
                                                className='chat-btn-copy'
                                                icon={<CopyIcon />}
                                                onClick={() => handleCopy(item)}
                                            />
                                        </Tooltip>
                                    )}
                                    <div onClick={(e) => onCopyToDoc(e)} className='chat-btn-generate-doc'>
                                        <Tooltip title='Create New Doc'>
                                            <CopyOutlined />
                                        </Tooltip>
                                    </div>
                                    {index === messages.length - 1 ? (
                                        <div onClick={onRegenerate} className='chat-btn-regenerate'>
                                            <Tooltip title='Regenerate'>
                                                <RedoOutlined />
                                            </Tooltip>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </List.Item>
            ) : null}
        </div>
    );
};
