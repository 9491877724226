import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import ScrollToBottom from 'react-scroll-to-bottom';
import { CloudUploadOutlined, UploadOutlined, DownOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Flex, notification, Radio, Row, Typography, Upload, Dropdown, Menu } from 'antd';
import cn from 'classnames';
import ChatGPT3 from '../../../../components/Icons/ChatGPT3/ChatGPT3';
import ChatGPT4 from '../../../../components/Icons/ChatGPT4/ChatGPT4';
import ClaudeIcon from '../../../../components/Icons/ClaudeIcon';
import { useBreakpoint } from '../../../../hooks/media';
import { useAuthContext } from '../../../AuthPage/context';
import { uploadImage } from '../ImageUpscalePage/hooks';
import { useBilling } from '../SettingsPage/pages/BillingPage/hooks';
import { ChatConversation } from './components/Conversation';
import { useConversationContext } from './components/Conversation/context';
import { ChatInput } from './components/Input';
import { beforeUpload } from './components/Input/Components/utils/upload';
import { KnowledgeBaseDrawer } from './components/KnowledgeBase';
import { ChatMessages } from './components/Messages';
import PromptLibrary from './components/PromptLibrary';
import ShareModal from './components/ShareModal';
import WelcomeModal from './components/WelcomeModal';
import './ChatPage.scss';

const { Dragger } = Upload;

const leftColSettings = {
    lg: { span: 18, order: 1 },
    md: { span: 24, order: 1 },
    sm: { span: 24, order: 1 },
    xs: { span: 24, order: 1 }
};

const rightColSettings = {
    lg: { span: 6, order: 2 },
    md: { span: 24, order: 2 },
    sm: { span: 24, order: 1 }
};

const engineChoice = [
    {
        engine: 'GPT-4',
        icon: <ChatGPT4 />,
        title: 'Best Model',
        description: 'Ideal for tasks that demand \n' + 'creativity and advanced reasoning.'
    },
    {
        engine: 'Claude 3',
        icon: <ClaudeIcon />,
        title: 'Deep Model',
        description:
            'Designed for extensive context—ideal \n' + 'for long detailed narratives and \n' + 'complex discussions.'
    }
];

const gpt35Options = [
    { key: 'gpt-4o-mini', label: 'GPT-4o mini' },
    { key: 'GPT-3.5', label: 'GPT-3.5' }
];

const ChatEngineDropdown = ({ engine, onEngineChange }) => {
    const isGPT35Model = engine === 'gpt-4o-mini' || engine === 'GPT-3.5';

    const handleMenuClick = ({ key }) => {
        onEngineChange(key);
    };

    const menu = (
        <Menu onClick={handleMenuClick}>
            {gpt35Options.map((option) => (
                <Menu.Item key={option.key}>{option.label}</Menu.Item>
            ))}
        </Menu>
    );

    return (
        <Dropdown overlay={menu} trigger={['click']}>
            <Button
                icon={<ChatGPT3 />}
                className={isGPT35Model ? 'selected' : ''}
            >
                {isGPT35Model ? engine : 'GPT-3.5'} <DownOutlined />
            </Button>
        </Dropdown>
    );
};

const MobileChatEngineDropdown = ({ engine, onEngineChange, isFreePlan }) => {
    const getEngineIcon = (engineName) => {
        switch (engineName) {
            case 'GPT-4':
                return <ChatGPT4 />;
            case 'Claude 3':
                return <ClaudeIcon />;
            case 'gpt-4o-mini':
            case 'GPT-3.5':
                return <ChatGPT3 />;
            default:
                return <ChatGPT3 />;
        }
    };

    const handleMenuClick = ({ key }) => {
        onEngineChange(key);
    };

    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key='gpt-4o-mini'>
                <ChatGPT3 /> GPT-4o mini
            </Menu.Item>
            <Menu.Item key='GPT-3.5'>
                <ChatGPT3 /> GPT-3.5
            </Menu.Item>
            <Menu.Item key='GPT-4' disabled={isFreePlan}>
                <ChatGPT4 /> GPT-4
            </Menu.Item>
            <Menu.Item key='Claude 3' disabled={isFreePlan}>
                <ClaudeIcon /> Claude 3
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={menu} trigger={['click']}>
            <Button className='mobile-chat-engine-dropdown'>
                {getEngineIcon(engine)} {engine} <DownOutlined />
            </Button>
        </Dropdown>
    );
};

export const Popover = ({ btn, billing }) => {
    return (
        <div className='chat-btn-engine__wrap__popover'>
            <div>
                <Typography.Title level={3}>{btn.title}</Typography.Title>
                <p>{btn.description}</p>
                <span>
                    {btn.engine === 'GPT-4.0' || btn.engine === 'Claude 3'
                        ? 'Available exclusively for members'
                        : 'Available for free and paid members'}
                    {billing?.state?.plan?.planType === 'free' && <NavLink to='/settings/billing'> upgrade</NavLink>}
                </span>
            </div>
        </div>
    );
};

export default function ChatPage({ setDocToken, collapsed, ctrlRef }) {
    const [dragZoneVisibility, setDragZoneVisibility] = useState(false);
    const dragBoxRef = useRef(null);
    const wrapperRef = useRef(null);
    const dragBox = dragBoxRef.current;
    const wrapper = wrapperRef.current;
    const [showWelcomeModal, setShowWelcomeModal] = useState(false);
    const [showShareModal, setShowShareModal] = useState(false);
    const auth = useAuthContext();
    const { isMobile } = useBreakpoint();
    const { conversation, message } = useConversationContext();
    const billing = useBilling();
    const org = auth.org.getCurrent();
    const isStartChat = message.state.data.docs.length < 1;
    const [engine, setEngine] = useState(auth.profile.chatEngine || 'gpt-4o-mini');
    const { actions } = conversation;
    const [fileList, setFileList] = useState([]);

    const chooseEngine = async (value) => {
        setEngine(value);
        try {
            await auth.updateProfile(
                {
                    chatEngine: value
                },
                false
            );
        } catch (error) {
            notification.error({
                placement: 'top',
                message: error.response?.data?.message || 'Something went wrong'
            });
        }
    };

    const getEngineIcon = (engineName) => {
        switch (engineName) {
            case 'GPT-4':
                return <ChatGPT4 />;
            case 'Claude 3':
                return <ClaudeIcon />;
            case 'gpt-4o-mini':
            case 'GPT-3.5':
                return <ChatGPT3 />;
            default:
                return <ChatGPT3 />;
        }
    };

    const onNewChat = () => {
        if (ctrlRef.current) ctrlRef.current.abort();

        message.actions.setUploadFiles({ content: [] });

        actions.create();
    };

    useEffect(() => {
        billing.actions.fetch(org);

        setShowWelcomeModal(() => auth?.profile?.modalActiveStatuses?.welcome);

        return () => {
            ctrlRef.current?.abort();
        };
    }, []);

    const onDragEnter = (e) => {
        setDragZoneVisibility(true);
        e.stopPropagation();
        e.preventDefault();
    };

    const onMouseLeave = (e) => {
        setDragZoneVisibility(false);
        e.stopPropagation();
        e.preventDefault();
    };

    const onDragLeave = (e) => {
        setDragZoneVisibility(false);
        e.stopPropagation();
        e.preventDefault();
    };

    const onDrop = (e) => {
        e.preventDefault();
        setDragZoneVisibility(false);
    };

    useEffect(() => {
        if (dragBox) {
            dragBox.addEventListener('mouseleave', onMouseLeave);
            dragBox.addEventListener('dragleave', onDragLeave);
            dragBox.addEventListener('drop', onDrop);
        }

        if (wrapper) {
            wrapper.addEventListener('dragenter', onDragEnter);
        }

        return () => {
            if (dragBox) {
                dragBox.removeEventListener('mouseleave', onMouseLeave);
                dragBox.removeEventListener('dragleave', onDragLeave);
                dragBox.removeEventListener('drop', onDrop);
            }
            if (wrapper) {
                wrapper.removeEventListener('dragenter', onDragEnter);
            }
        };
    }, [dragBoxRef.current]);

    if (!conversation?.state?.current) return null;

    const lastMsg = message.state.data.docs.at(-1);

    const onNewMessage = (formData, content, regenerate = false) => {
        formData.append('engine', engine);

        // Prevent from pushing new message if last message is not responded
        if (!lastMsg || lastMsg.respond) {
            ctrlRef.current = new AbortController();
            message.actions.create(conversation.state.current._id, formData, ctrlRef.current, content);
        }
    };

    const stopGenerating = () => {
        ctrlRef.current.abort();
    };

    const isChatInputDisabled = message.state.data.docs.length && !lastMsg?.respond;

    const isFreePlan = billing?.state?.plan?.planType === 'free';

    const isUploadDisabled = billing?.state?.plan?.status !== 'active' || isFreePlan;

    const onChangeFile = async ({ file, fileList: newFileList }) => {
        const processFile = async (fileToProcess) => {
            if (fileToProcess instanceof Blob) {
                const beforeUploadCheck = await beforeUpload(fileToProcess);
                if (beforeUploadCheck) {
                    // Update the file list state
                    const updatedFileList = newFileList.map(f => ({
                        ...f,
                        status: f.uid === fileToProcess.uid ? 'done' : f.status
                    }));
                    setFileList(updatedFileList);
                    message.actions.setUploadFiles({ content: updatedFileList });
    
                    // Change the model to Claude 3 when a file is uploaded
                    if (updatedFileList.length > 0 && engine !== 'Claude 3') {
                        chooseEngine('Claude 3');
                    }
                    
                    // Reset drag zone visibility after successful upload
                    setDragZoneVisibility(false);
                }
            } else {
                throw new Error('Invalid file format');
            }
        };
    
        try {
            if (file.status === 'removed') {
                // Handle file removal
                const updatedFileList = fileList.filter(f => f.uid !== file.uid);
                setFileList(updatedFileList);
                message.actions.setUploadFiles({ content: updatedFileList });
                return;
            }

            const fileToCheck = file.originFileObj || file;
            await processFile(fileToCheck);
        } catch (error) {
            setTimeout(async () => {
                try {
                    const updatedFile = newFileList.find(f => f.uid === file.uid);
                    if (updatedFile) {
                        await processFile(updatedFile.originFileObj || updatedFile);
                    } else {
                        throw new Error('File not found in the list');
                    }
                } catch (retryError) {
                    notification.error({
                        message: 'Upload Error',
                        description: 'The file could not be processed. Please try again.'
                    });
                    // Remove the failed file from the list
                    const updatedFileList = fileList.filter(f => f.uid !== file.uid);
                    setFileList(updatedFileList);
                    message.actions.setUploadFiles({ content: updatedFileList });
                } finally {
                    // Reset drag zone visibility even if there's an error
                    setDragZoneVisibility(false);
                }
            }, 0);
        }
    };

    return (
        <Row className='chat-wrap' ref={wrapperRef}>
            <Col {...leftColSettings}>
                <Dragger
                    disabled={isUploadDisabled}
                    accept='.pdf, .csv, .txt, .jpeg, .png, .gif, .webp, .jpg'
                    maxCount={3}
                    onChange={onChangeFile}
                    name='file'
                    multiple
                    customRequest={uploadImage}
                    showUploadList
                    className={cn({ 'chat-wrap__drag': true }, { active: dragZoneVisibility })}
                    fileList={message.state.uploadFileList}
                    openFileDialogOnClick={false}
                >
                    <div ref={dragBoxRef} className='chat-wrap__drag__wrapper'>
                        <div className='chat-wrap__drag__title'>
                            <CloudUploadOutlined />
                            {isUploadDisabled ? (
                                <React.Fragment>
                                    <Typography.Title>Available to paid members only!</Typography.Title>
                                    <Typography.Text>Please upgrade your plan</Typography.Text>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <Typography.Title>Add Anything</Typography.Title>
                                    <Typography.Text>Drop any file here to add it to the conversation</Typography.Text>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </Dragger>
                <div className="chat-inner-wrap">
                    {!isStartChat && (
                        <div className='chat-share'>
                            <Flex>
                                <Flex gap={4} align='center'>
                                    {getEngineIcon(engine)}
                                    <Typography.Text>{engine}</Typography.Text>
                                </Flex>
                            </Flex>
                            <Button
                                disabled={!message.state.data?.docs?.length}
                                onClick={() => setShowShareModal(true)}
                                size='small'
                                type='default'
                                icon={<UploadOutlined />}
                            />
                            {isMobile && ( <Button icon={<PlusOutlined />} onClick={onNewChat} /> )}
                        </div>
                    )}
                    <div className="chat-messages-wrap">
                        {isStartChat && (
                            isMobile ? (
                                <MobileChatEngineDropdown
                                    engine={engine}
                                    onEngineChange={chooseEngine}
                                    isFreePlan={isFreePlan}
                                />
                            ) : (
                                <Radio.Group
                                    onChange={(e) => chooseEngine(e.target.value)}
                                    value={engine}
                                    className='chat-btn-engine__wrap'
                                >
                                    <ChatEngineDropdown
                                        engine={engine}
                                        onEngineChange={chooseEngine}
                                    />
                                    {engineChoice.map((btn) => (
                                        <Radio.Button
                                            disabled={(btn.engine === 'GPT-4' || btn.engine === 'Claude 3') && isFreePlan}
                                            key={btn.engine}
                                            value={btn.engine}
                                            className={engine === btn.engine ? 'isActive' : null}
                                        >
                                            {btn.icon}
                                            {btn.engine}
                                            <Popover btn={btn} billing={billing} />
                                        </Radio.Button>
                                    ))}
                                </Radio.Group>
                            )
                        )}
                        <ScrollToBottom className='scroll-to-bottom' followButtonClassName='scroll-button'>
                            <ChatMessages
                                conversation={conversation}
                                onNewMessage={onNewMessage}
                                setDocToken={setDocToken}
                                auth={auth}
                                avatar={auth.profile.avatar}
                                message={message}
                            />
                        </ScrollToBottom>
                    </div>
                    <div className="chat-input-container">
                        {message.state.loader.streaming && (
                            <Button className='chat-button-stop' onClick={stopGenerating}>
                                Stop Generating
                            </Button>
                        )}
                        <ChatInput
                            setEngine={chooseEngine}
                            isUploadDisabled={isUploadDisabled}
                            conversation={conversation}
                            create={onNewMessage}
                            disabled={isChatInputDisabled}
                            message={message}
                            ctrlRef={ctrlRef}
                            engine={engine}  
                        />
                        <PromptLibrary collapsed={collapsed} conversation={conversation} message={message} />
                        <KnowledgeBaseDrawer collapsed={collapsed} conversation={conversation} message={message} />
                    </div>
                </div>
            </Col>
            {!isMobile && (
                <Col {...rightColSettings}>
                    <ChatConversation ctrlRef={ctrlRef} conversation={conversation} message={message} />
                </Col>
            )}
            {showWelcomeModal && (
                <WelcomeModal open={showWelcomeModal} onClose={() => setShowWelcomeModal(false)} />
            )}
            {showShareModal && (
                <ShareModal
                    conversation={conversation}
                    auth={auth}
                    message={message}
                    open={showShareModal}
                    onClose={() => setShowShareModal(false)}
                />
            )}
        </Row>
    );
}
