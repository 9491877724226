import React from 'react';
import { Typography } from 'antd';

export const PopoverContent = ({ isUploadDisabled }) => {
    let text = 'Accept formats: jpeg, png, gif, webp, pdf, txt, csv, etc. (5 max, 5MB each)';

    if (isUploadDisabled) {
        text = 'Available to paid members';
    }

    return <Typography.Text>{text}</Typography.Text>;
};
