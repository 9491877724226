import React from 'react';
import {
    AudioOutlined,
    BulbOutlined,
    FieldTimeOutlined,
    FileImageOutlined,
    FileTextOutlined,
    MessageOutlined,
    SettingOutlined
} from '@ant-design/icons';

export const mainMenu = [
    {
        key: 'chat-wrapper',
        label: 'Chat',
        children: [
            {
                key: '/chat',
                icon: <MessageOutlined />,
                label: 'AI Chat'
            },
            {
                key: '/tools',
                icon: <BulbOutlined />,
                label: 'Prompts'
            }
        ]
    },
    {
        key: '/art',
        label: 'Image',
        children: [
            {
                key: '/art/image-generator',
                icon: <FileImageOutlined />,
                label: 'Image Generator'
            },
            {
                key: '/art/image-templates',
                icon: <BulbOutlined />,
                label: 'Templates'
            },
            // {
            //     key: '/art/image-upscale',
            //     icon: <CloudUploadOutlined />,
            //     label: 'Image Upscale'
            // },
            {
                key: '/art/history',
                icon: <FieldTimeOutlined />,
                label: 'History'
            }
        ]
    },
    {
        key: '/speech',
        label: 'Audio',
        children: [
            {
                key: '/speech/just-speak',
                icon: <AudioOutlined />,
                label: 'Text to Speech'
            },
            {
                key: '/speech/history',
                icon: <FieldTimeOutlined />,
                label: 'History'
            }
        ]
    },
    // {
    //     key: '/video',
    //     label: 'Video',
    //     children: [
    //         {
    //             key: '/video/image-to-video',
    //             icon: <VideoCameraOutlined />,
    //             label: 'Image to Video'
    //         },
    //         {
    //             key: '/video/history',
    //             icon: <FieldTimeOutlined />,
    //             label: 'History'
    //         }
    //     ]
    // },
    {
        key: 'docs-wrapper',
        label: 'Docs',
        children: [
            {
                key: '/document/history',
                icon: <FileTextOutlined />,
                label: 'My Docs'
            }
        ]
    }
    // {
    //     key: 'knowledge-base',
    //     label: 'Knowledge Base',
    //     children: [
    //         {
    //             key: '/knowledge-base/documents',
    //             icon: <FileTextOutlined />,
    //             label: 'Documents'
    //         },
    //         {
    //             key: '/knowledge-base/videos',
    //             icon: <FileTextOutlined />,
    //             label: 'Videos'
    //         }
    //     ]
    // }
];

export const subMenu = [
    {
        key: '/settings',
        icon: <SettingOutlined />,
        label: 'Settings',
        children: [
            {
                key: '/settings/team',
                label: 'Team'
            },
            {
                key: '/settings/profile',
                label: 'Profile'
            },
            {
                key: '/settings/billing',
                label: 'Billing'
            }
        ]
    }
];

// if (NODE_ENV === 'dev') {
//     mainMenu.push({
//         key: '/books/create',
//         icon: <EditOutlined />,
//         label: 'Create a Book'
//     });
//
//     subMenu.push({
//         key: '/create-a-book',
//         icon: <EditOutlined />,
//         label: 'Create a Book'
//     });
// }
