import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Icon from '@ant-design/icons';
import { Typography } from 'antd';
import { isMobile } from 'react-device-detect';

import { useAuthContext } from '../../../../../AuthPage/context';
import { useConversationContext } from '../Conversation/context';
import BulbIcon from '../../../../../../components/Icons/BulbIcon/BulbIcon';
import { surveyConfig } from '../../../../../../constants/surveyConfig';

export default function UserSurvey({ message }) {
    const { conversation } = useConversationContext();
    const auth = useAuthContext();
    const [options, setOptions] = useState([]);
    const { state, actions } = conversation;

    useEffect(() => {
        let foundOption = surveyConfig.choices.find((element) => element.option === auth?.profile?.userProf);
        if (!foundOption) foundOption = surveyConfig.choices[0];

        const examplesNumber = isMobile ? 2 : 4; // Using isMobile from react-device-detect

        const newOptions = state.loader.updateOptions
            ? _.sampleSize(foundOption?.examples, examplesNumber)
            : foundOption?.examples?.slice(0, examplesNumber);

        setOptions(newOptions);
        actions.updateOptions(false);
    }, [auth?.profile?.userProf, state.loader.updateOptions]);

    const onExampleClick = (value) => {
        message.actions.updateInput(value);
    };

    return (
        <div className='user-survey-wrap'>
            <div className='user-survey-inner'>
                <Icon component={BulbIcon} className='user-survey-icon' />
                <Typography.Title level={2} className='user-survey-title'>
                    How can I help you?
                </Typography.Title>
                <Typography.Paragraph className='user-survey-text'>
                    Ask anything & get instant help
                </Typography.Paragraph>
                <ul className='user-survey-examples'>
                    {options.map((example, keyExample) => (
                        <li onClick={() => onExampleClick(example)} key={keyExample}>
                            <span>{example}</span>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}
