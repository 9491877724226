import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useScrollToBottom } from 'react-scroll-to-bottom';
import Icon, { ArrowRightOutlined, AudioOutlined, SendOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Space, Upload } from 'antd';
import cn from 'classnames';
import recordImg from '../../../../../../assets/images/microphone_icon.png';
import PauseAudio from '../../../../../../components/Icons/PauseAudio/PauseAudio';
import UploadClip from '../../../../../../components/Icons/UploadClip';
import { useBreakpoint } from '../../../../../../hooks/media';
import { AttachedFile } from '../../../../../../shared/components/AttachedFile';
import { uploadImage } from '../../../ImageUpscalePage/hooks';
import AudioRecorder from '../../lib/AudioRecorder';
import EasyInputForm from './Components/Coundown/EasyInputForm';
import { PopoverContent } from './Components/Popover';
import { beforeUpload } from './Components/utils/upload';
import './index.module.scss';

export function ChatInput({ setEngine, isUploadDisabled, conversation, message, create, disabled, engine }) {
    const scrollToBottom = useScrollToBottom();
    const { isMobile } = useBreakpoint();
    const history = useHistory();
    const location = useLocation();
    const { actions } = conversation;
    const [value, setValue] = useState(location?.state?.text || '');
    const [recording, setRecording] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const inputChatRef = useRef(null);
    const [insertWords, setInsertWords] = useState([]);

    const arrWordsInPrompt = useCallback((str) => {
        const regex = /\[([^\]]+)\]/g;
        return str.match(regex)?.map((match) => match.slice(1, -1)) || [];
    }, []);

    const handleUploadClick = useCallback(() => {
        // If engine is not set, we'll default to 'Claude 3' as per the previous logic
        if (engine !== 'Claude 3') {
            setEngine('Claude 3');
        }
    }, [engine, setEngine]);

    const focusInput = useCallback(() => {
        if (inputChatRef.current) {
            inputChatRef.current.focus();
        }
    }, []);

    const replaceWordsWithNumbers = useCallback((str) => {
        let count = 0;
        return str.replace(/\[[^\]]+\]/g, function (match) {
            return '[' + count++ + ']';
        });
    }, []);

    useEffect(() => {
        const isTextFromPrompt = location?.state?.text;
        const isBracketsInPrompt = isTextFromPrompt?.includes('[');

        if (isTextFromPrompt && isBracketsInPrompt) {
            setOpen(true);
            const arrayWordsInBrackets = arrWordsInPrompt(isTextFromPrompt);
            setInsertWords([...arrayWordsInBrackets]);
        }

        if (location?.state?.text) {
            history.replace({ state: { text: '' } });
        } else {
            setValue(message.state.chatInput);
        }
    }, [message.state.chatInput, location?.state?.text, history, arrWordsInPrompt]);

    useEffect(() => {
        inputChatRef.current?.focus();
    }, [location.pathname]);

    const recorder = useMemo(
        () =>
            new AudioRecorder({
                onStop: (transcription) => {
                    setValue(transcription);
                    setRecording(false);
                },
                onError: () => {
                    setRecording(false);
                }
            }),
        []
    );

    const handleOk = useCallback(() => {
        setIsModalOpen(false);
    }, []);

    const handleCancel = useCallback(() => {
        setIsModalOpen(false);
    }, []);

    const onChange = useCallback((e) => {
        setValue(e.target.value);
    }, []);

    const rows = value.split('\n').length;

    const onSubmit = useCallback((e) => {
        e.preventDefault();

        if (!value.trim()) return;

        const formData = new FormData();

        for (let i = 0; i < message.state.uploadFileList?.length; i++) {
            formData.append('file[]', message.state.uploadFileList[i].originFileObj);
        }

        formData.append('content', value);

        create(formData, value);

        setValue('');
        scrollToBottom({ behavior: 'smooth' });
    }, [value, message.state.uploadFileList, create, scrollToBottom]);

    const handleKeyDown = useCallback((e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            onSubmit(e);
        }
    }, [onSubmit]);

    const onVoiceRecordStart = useCallback(async () => {
        await recorder.start();

        if (!recorder.stream) {
            setIsModalOpen(true);
            return;
        }

        setRecording(true);

        if (AudioRecorder.isSpeechRecognitionSupported()) {
            recorder.startSpeechRecognition();
        }
    }, [recorder]);

    const onVoiceRecordPause = useCallback(() => {
        recorder.stop();
    }, [recorder]);

    const onCreate = useCallback((newValues) => {
        const arr = Object.entries(newValues);
        const valueWithIndex = replaceWordsWithNumbers(value);
        const newInput = arr.reduce((acc, currentValue, index) => acc.replace(`[${index}]`, arr[index][1]), valueWithIndex);
        setValue(newInput);
        setOpen(false);
    }, [value, replaceWordsWithNumbers]);

    const handlePaste = useCallback((event) => {
        const items = event.clipboardData.items;
        
        for (const item of items) {
            if (item.type.indexOf('image') !== -1) {
                event.preventDefault();
                
                const blob = item.getAsFile();
                if (blob) {
                    beforeUpload(blob).then(isValid => {
                        if (isValid) {
                            const reader = new FileReader();
                            reader.onload = (e) => {
                                const newFile = {
                                    uid: `${Date.now()}`,
                                    name: `pasted_image_${Date.now()}.png`,
                                    status: 'done',
                                    url: e.target.result,
                                    originFileObj: blob,
                                    type: blob.type
                                };
                                const newFileList = [...message.state.uploadFileList, newFile];
                                message.actions.setUploadFiles({ content: newFileList });
                            };
                            reader.readAsDataURL(blob);
                        }
                    }).catch(() => {
                        // console.error(`File validation failed: ${error.message}`);
                    });
                }
            }
        }
    }, [message.actions, message.state.uploadFileList]);

    const onChangeFile = useCallback(async ({ file, fileList }) => {
        if (file.status === 'done') {
            const isValid = await beforeUpload(file.originFileObj);
            if (isValid) {
                message.actions.setUploadFiles({ content: fileList });
            }
        }
    }, [message.actions]);

    const handleRemoveFile = useCallback((fileToRemove) => {
        const newFileList = message.state.uploadFileList.filter(file => file.uid !== fileToRemove.uid);
        message.actions.setUploadFiles({ content: newFileList });
        
        // If all files are removed, reset the engine to its default value
        if (newFileList.length === 0) {
            setEngine(engine === 'Claude 3' ? 'gpt-4o-mini' : engine);
        }
    }, [message.actions, message.state.uploadFileList, setEngine, engine]);

    const isInsertWord = insertWords.length > 0;

    return (
        <React.Fragment>
            {message.state.uploadFileList.length > 0 && (
                <div style={{maxWidth: '750px', margin: 'auto', display: 'flex' }} className={cn('attached-files-list' )}>
                    {message.state.uploadFileList.map((file) => (
                        <AttachedFile 
                            key={file.uid}
                            file={file} 
                            actions={{
                                remove: () => handleRemoveFile(file)
                            }}
                        />
                    ))}
                </div>
            )}

            <div 
                className={cn({
                    'chat-input': true,
                    'files-attached': message.state.uploadFileList.length > 0
                })}
                onClick={focusInput}
            >
                {isInsertWord && open && (
                    <EasyInputForm openModal={open} setVisible={setOpen} onCreate={onCreate} arrayWords={insertWords} />
                )}
                <Modal
                    className='modal-record'
                    title='Voice Input'
                    open={isModalOpen}
                    onOk={handleOk}
                    closable={false}
                    centered
                    footer={[
                        <Button key='submit' type='primary' onClick={handleOk}>
                            Continue
                            <ArrowRightOutlined />
                        </Button>
                    ]}
                    onCancel={handleCancel}
                >
                    <img alt='record image' src={recordImg} />
                    <p>Microphone access is needed for input.</p>
                    <p>English only supported at the moment.</p>
                </Modal>
                <Upload
                    name='input-file-upload'
                    listType='picture'
                    className={cn({
                        'chat-upload': true,
                        active: !isUploadDisabled
                    })}
                    customRequest={uploadImage}
                    showUploadList={false}
                    maxCount={3}
                    onChange={onChangeFile}
                    accept='.pdf, .csv, .txt, .jpeg, .png, .gif, .webp, .jpg'
                >
                    <Button
                        disabled={isUploadDisabled || disabled}
                        onClick={handleUploadClick}
                        type='primary'
                        icon={<Icon className='chat-upload-clip' component={UploadClip} />}
                    />
                    <div className='chat-upload__popover'>
                        <PopoverContent isUploadDisabled={isUploadDisabled} />
                    </div>
                </Upload>
                <Input.TextArea
                    className='chat-input-textarea'
                    autoSize={{ minRows: 1, maxRows: 6 }}
                    placeholder={isMobile ? 'Write anything important' : 'Write a paragraph about the meaning of life'}
                    value={value}
                    size='large'
                    onChange={onChange}
                    onPaste={handlePaste}
                    onKeyDown={handleKeyDown}
                    rows={rows}
                    ref={inputChatRef}
                />
                <Space className='chat-action-icons'>
                    {(value || !AudioRecorder.isAudioSupported()) && (
                        <Button
                            disabled={disabled}
                            className='chat-input-button'
                            type='primary'
                            icon={<SendOutlined />}
                            onClick={onSubmit}
                        />
                    )}
                    {!value && AudioRecorder.isAudioSupported() ? (
                        recording ? (
                            <Button
                                disabled={disabled}
                                className='chat-input-button record-btn'
                                type='primary'
                                icon={<Icon component={PauseAudio} />}
                                onClick={onVoiceRecordPause}
                            />
                        ) : (
                            <Button
                                disabled={disabled}
                                className='chat-input-button record-btn'
                                type='primary'
                                icon={<AudioOutlined />}
                                onClick={onVoiceRecordStart}
                            />
                        )
                    ) : null}
                </Space>
            </div>
            <div className='chat-input-prompt'>
                Need help writing prompts? Try our <a onClick={() => actions.promptsOpen(true)}>Prompt Library</a>
            </div>
        </React.Fragment>
    );
}